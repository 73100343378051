import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    erro500: false,
    errorAtoNaoEncontrado: false,
    errorMaisdeMilResultados: false,
    errorData: false,
    errorAxios: false,
    dadosFormatadosAPI: [],
    nlei: "",
    ano: "",
    tipoAtoNormativo: [
      { value: 0, text: "Tipo de Lei", disabled: true },
      { value: 1, text: "DECRETO - R" },
      { value: 2, text: "DECRETO - S" },
      { value: 3, text: "LEI COMPLEMENTAR" },
      { value: 4, text: "LEI DELEGADA" },
      { value: 5, text: "LEI ORDINÁRIA" },
    ],
    dataInicio: "",
    dataFinal: "",
    descricao: "",
    listaDadosAPI: "",
    showProgressBar: false,
    lista: "",
    contadorLista: "",
  },
  getters: {
    listarListaDadosAPI(state) {
      return state.listaDadosAPI;
    },
    listarTipoAtoNormativo(state) {
      return state.tipoAtoNormativo;
    },
    showProgressBar(state) {
      return state.showProgressBar;
    },
  },
  mutations: {
    atualizarLista(state, novaLista) {
      state.lista = novaLista;
    },
    atualizarShowProgressBar(state, showTrue) {
      state.showProgressBar = showTrue;
    },
    atualizarListaDadosAPI(state, novaListaDadosAPI) {
      state.listaDadosAPI = novaListaDadosAPI;
    },
    atualizaErroAxios(state, novoErro) {
      state.errorAxios = novoErro;
    },
    atualizaErrorMaisdeMilResultados(state, novoErro) {
      state.errorMaisdeMilResultados = novoErro;
    },
    atualizarAtoNaoEncontrado(state, novoErro) {
      state.erro500 = novoErro;
    },
    atualizarErro500(state, novoErro) {
      state.errorAtoNaoEncontrado = novoErro;
    },
    atualizarErrorData(state, novoErro) {
      state.errorData = novoErro;
    },
    atualizaContador(state, novoNumero) {
      state.contadorLista = novoNumero;
    },
  },
  actions: {
    atualizarLista({ commit }) {
      commit("atualizarShowProgressBar", true);
      axios
        .get("AtoNormativo/7006556")
        .then((response) => {
          console.log("Deu certo até aqui", response.data);
          commit("atualizarLista", response.data);
          commit("atualizarShowProgressBar", false);
        })
        .catch((error) => {
          commit("atualizarLista", error);
          commit("atualizarShowProgressBar", false);
        });
    },
    async pegarDadosAPI({ commit }, dados) {
      commit("atualizarListaDadosAPI", "");
      commit("atualizaContador", "");
      commit("atualizaErroAxios", false);
      commit("atualizarShowProgressBar", true);
      commit("atualizaErrorMaisdeMilResultados", false);
      commit("atualizarAtoNaoEncontrado", false);
      commit("atualizarErro500", false);

      if (
        dados.nlei ||
        dados.ano ||
        dados.descricao ||
        dados.dataInicial ||
        dados.dataFinal ||
        dados.tipoLei
      ) {
        if (!dados.nlei) dados.nlei = 0;
        if (!dados.ano) dados.ano = 0;
        if (!dados.tipoLei) dados.tipoLei = 0;
        if (!dados.dataInicial) dados.dataInicial = null;
        if (!dados.dataFinal) dados.dataFinal = null;
        if (!dados.descricao) dados.descricao = null;

        console.log(dados)

        axios
          .post("buscar", {
            numeroAtoNormativo: dados.nlei,
            anoAtoNormativo: dados.ano,
            tipoAtoNormativo: dados.tipoLei,
            publicacao: {
              dataInicioBusca: dados.dataInicial,
              dataFimBusca: dados.dataFinal,
            },
            descricao: dados.descricao,
          })
          .then((response) => {
            console.log(response.data);
            let dados = [];
            let contador = 0;

            for (let i = 0; i < response.data.length; i++) {
              let dataAPI = new Date(response.data[i].publicacao);

              let dataAPIFormatada = dataAPI.toLocaleDateString({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              });

              dados.push({
                autoriaAtoNormativo: response.data[i].autoriaAtoNormativo,
                descricao: response.data[i].descricao,
                links: response.data[i].links,
                numeroAnoAtoNormativo: response.data[i].numeroAnoAtoNormativo,
                publicacao: dataAPIFormatada,
                tipoAtoNormativo: response.data[i].tipoAtoNormativo,
                loading: false,
                error: false,
              });
              contador += 1;
              // if (response.data[i]) {
              // } else {
              //   console.log("Não tem nada");
              // }
            }

            dados.sort(async (a, b) => {
              const numA = await parseInt(
                a.numeroAnoAtoNormativo.split("/")[0]
              );
              const numB = await parseInt(
                b.numeroAnoAtoNormativo.split("/")[0]
              );

              return numB - numA;
            });

            (async () => {
              await commit("atualizaContador", contador);
              await commit("atualizarListaDadosAPI", dados);

              if (dados) {
                commit("atualizarShowProgressBar", false);
                commit("atualizaErroAxios", false);
                commit("atualizaErrorMaisdeMilResultados", false);
                commit("atualizarAtoNaoEncontrado", false);
              }
            })();
          })
          .catch((error) => {
            if (
              error.response.data ===
              "Sua consulta retorna mais de 1.000 resultados, por favor aplique mais filtros na busca."
            ) {
              commit("atualizarShowProgressBar", false);
              commit("atualizaErrorMaisdeMilResultados", true);
            } else if (
              error.response.data === "Não foi encontrado ato normativo."
            ) {
              commit("atualizarShowProgressBar", false);
              commit("atualizarAtoNaoEncontrado", true);
            } else {
              dados = "";
              commit("atualizarListaDadosAPI", dados);
              commit("atualizarShowProgressBar", false);
              commit("atualizarErro500", true);
            }
          });
      } else {
        commit("atualizarShowProgressBar", false);
        commit("atualizaErroAxios", true);
      }
    },

    async consultarRelacionados({ commit, state }, norma) {
      await axios
        .post(`relacionado`, {
          numeroDaNorma: `${norma.numeroAnoAtoNormativo.split("/")[0]}`,
          anoDaNorma: norma.numeroAnoAtoNormativo.split("/")[1],
          numeroDaNormaRelacionada: 1,
          anoDaNormaRelacionada: 1,
        })
        .then((response) => {
          if (response.status === 200) {
            const resposta = response.data;
            let dados = state.listaDadosAPI;
            const index = dados.findIndex(
              (item) =>
                item.numeroAnoAtoNormativo === norma.numeroAnoAtoNormativo
            );

            if (index !== -1) {
              dados[index].relacionados = resposta.map((item) => ({
                normaRelacionada: item.numeroDaNormaRelacionada,
              }));
              // return dados;
            }
            commit("atualizarListaDadosAPI", dados);
          } else {
            console.error("Não existe para essa norma");
          }
        })
        .catch((error) => {
          console.log("Ocorreu um erro ao consultar as normas relacionadas");
          console.error(error);
        });
    },
  },
});
