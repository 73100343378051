<template >
  <v-container v-hotkey="keymap">
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="container-inputs">
        <div class="inputs1">
          <v-row>
            <v-col md="4" xs="6">
              <v-text-field background-color="white" type="text" label="N°" v-model="nlei" filled dense></v-text-field>
            </v-col>

            <v-col md="4" xs="6">
              <v-text-field background-color="white" type="number" label="Ano" v-model="ano" filled dense
                required></v-text-field>
            </v-col>

            <v-col md="4" xs="2">
              <v-select background-color="white" label="Tipo Lei" :items="tipoAtoNormativo" v-model="tipoLei" filled
                dense></v-select>
            </v-col>
          </v-row>
        </div>

        <div class="inputs2">

          <v-row>
            <v-col class="Data Inicial" type="number" md="4" sm="6" xs="6">

              <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field background-color="white" filled dense :value="formatarDataInicial" label="Data Inicial"
                    append-icon="mdi-calendar-filter" v-bind="attrs" v-on="on">

                  </v-text-field>
                </template>
                <v-date-picker locale="pt" no-title v-model="dataInicial" @input="menu1 = false"></v-date-picker>
              </v-menu>

            </v-col>

            <v-col class="Data Final" type="number" md="4" sm="6" xs="6">
              <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="0" transition="scale-transition"
                offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field background-color="white" filled dense :value="formatarDataFinal" label="Data Final"
                    append-icon="mdi-calendar-filter" v-bind="attrs" v-on="on">

                  </v-text-field>
                </template>
                <v-date-picker locale="pt" no-title v-model="dataFinal" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" xs="2">
              <v-text-field background-color="white" filled dense v-model="descricao" label="Descrição"></v-text-field>
            </v-col>

          </v-row>
        </div>

        <div class="botoes-input">
          <v-btn @click="limparCampos" color="error" class="button-limpar-campos">
            Limpar campos
          </v-btn>
          <v-btn @click="buscarDados" color="info" class="button-buscar">
            Buscar
          </v-btn>
        </div>

      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'InputsForm',
  data: () => ({
    nlei: "",
    ano: "",
    tipoLei: "",
    dataInicial: "",
    dataFinal: "",
    descricao: "",
    menu1: false,
    menu2: false,
  }),
  computed: {
    ...mapState({
      tipoAtoNormativo: "tipoAtoNormativo",
      listaDadosAPI: "listaDadosAPI",
    }),
    formatarDataInicial() {
      return this.dataInicial ? moment(this.dataInicial).locale("pt-BR").format("L") : "";
    },
    formatarDataFinal() {
      return this.dataFinal ? moment(this.dataFinal).locale("pt-BR").format("L") : "";
    },
    keymap() {
      return {
        'enter': this.buscarDados
      }
    }
  },
  watch: {
    nlei(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      console.log(keyCode)

      this.nlei = this.nlei.replace(/\D/g, '');

      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault(); // Impedir a inserção do caractere
      }
    },
  },
  methods: {
    ...mapActions(["pegarDadosAPI"]),
    buscarDados() {
      let dados = {
        nlei: this.nlei,
        ano: this.ano,
        tipoLei: this.tipoLei,
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
        descricao: this.descricao,
      };
      this.pegarDadosAPI(dados)
    },
    limparCampos() {
      this.nlei = '',
        this.ano = '',
        this.tipoLei = '',
        this.dataInicial = '',
        this.dataFinal = '',
        this.descricao = ''
    }
  },
  mounted() {
    if (this.$route.query.norma || this.$route.query.norma != null) {
      const norma = this.$route.query.norma.split("/");
      console.log('teste', norma[0], norma[1])

      this.nlei = norma[0],
        this.ano = norma[1]

      let dados = {
        nlei: this.nlei,
        ano: this.ano,
        tipoLei: this.tipoLei,
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
        descricao: this.descricao,
      };
      this.pegarDadosAPI(dados);
    }
  },



}
</script>

<style lang="scss">
// .v-text-field--outlined fieldset {
//     color: rgb(255, 5, 5) !important;
// }

.container-inputs {
  background-color: rgb(235, 235, 235);
  border: 5px outside rgb(200, 102, 115);

  padding: 20px;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .botoes-input {
    display: flex;
    justify-content: right;
    align-items: center;

    .button-limpar-campos {
      margin-right: 5px;
      width: 150px;
    }

    .button-buscar {
      width: 150px;
    }
  }
}
</style>