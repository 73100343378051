import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import VueHotkey from 'v-hotkey';
import vuetify from './plugins/vuetify';

import 'material-icons/iconfont/material-icons.css';

// axios.defaults.baseURL = "https://conteudo.saude.es.gov.br/DevSEG/ConsultaNormas/";
axios.defaults.baseURL = "https://conteudo.seg.es.gov.br/APINormas/";
// axios.defaults.baseURL = "https://localhost:7064/";

Vue.use(VueHotkey);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
