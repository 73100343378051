<template>
  <v-app>

    <ConsultarAtos :dadosLista="listarListaDadosAPI" :total-pages="Math.ceil(listarListaDadosAPI.length / 10)"
      :total="listarListaDadosAPI.length" :per-page="15" :current-page="currentPage" @pagechanged="onPageChange" />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import ConsultarAtos from '../views/ConsultarAtos.vue';

import { mapGetters, mapState } from 'vuex';

export default {
  name: 'App',
  components: {
    ConsultarAtos
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  mounted: {
    ...mapState(["listaDadosAPI"])
  },
  computed: {
    ...mapGetters(['listarListaDadosAPI']),
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    }
  },
  watch: {
    listarListaDadosAPI(){
    },

  }
};
</script>