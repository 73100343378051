import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  iconfont: 'mdi'
});

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#007BFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#208BAA',
        success: '#4CAF50',
        warning: '#FFC107',
        sesa: '#C86673',
      },
      dark: {
        primary: colors.blue.lighten3
      }
    },
  },
});