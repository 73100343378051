<template>
  <v-container>

    <Inputs />

    <v-container class="contador-quantidade" v-if="contador">
      <div class="contador">
        {{ this.contador == 1
          ? `${this.contador} resultado`
          : `${this.contador} resultados`
        }}
      </div>
      <div class="quantidade">
        <v-row>
          <v-col cols="12" md="5">
            <v-select filled dense :items="qtd" v-model="qtdModel"></v-select>
          </v-col>
          <v-col md="7" v-if="calculaTamanhoPagina === false">
            Por página
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div class="spinner" v-if="showProgressBar">
      <v-progress-circular :size="70" :width="7" color="sesa" indeterminate></v-progress-circular>
    </div>

    <div class="errorAxios" v-if="errorAxios">

      <h2>Verifique se todos os campos estão preenchidos</h2>

    </div>

    <div class="errorMaisDeMilResultados" v-if="errorMaisdeMilResultados">

      <h2>Sua consulta retorna mais de 1.000 resultados, por favor aplique mais filtros na busca.</h2>

    </div>

    <div class="errorMaisDeMilResultados" v-if="errorAtoNaoEncontrado">

      <h2>Não foi encontrado ato normativo.</h2>

    </div>
    
    <div class="errorMaisDeMilResultados" v-if="erro500">

      <h2>Ocorreu um erro na consulta.</h2>

    </div>

    <div v-if="calculaTamanhoPagina" class="card-para-celular">

      <v-expansion-panels class="cards-mobile" v-for="(items, index) in paginateData" :key="index">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="cabecalho">
              <v-row>
                <v-col cols="12" md="6" class="infos">
                  <div><b>N°:</b> {{ items.numeroAnoAtoNormativo }}</div>
                  <div><b>Autoria:</b> {{ items.autoriaAtoNormativo }}</div>
                  <div><b>Ato Normativo:</b> {{ items.tipoAtoNormativo }}</div>
                  <div><b>Publicação:</b> {{ items.publicacao }}</div>
                </v-col>
                <!-- <v-col cols="12" md="3" class="infos">
                  <div v-for="(link, index) in items.links" :key="index">
                    <a :href="link.uriDestino" target="_blank">{{ link.texto }} </a>
                  </div>
                </v-col> -->
              </v-row>
            </div>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row no-gutters>
              <v-col cols="12" md="3" class="infos">
                <div v-for="(link, index) in items.links" :key="index">
                  <a :href="link.uriDestino" target="_blank">{{ link.texto }}</a>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <div class="rodape">
                <v-col md="12">
                  <div><b>Descrição:</b> {{ items.descricao }}</div>
                </v-col>
              </div>
            </v-row>
            <v-row no-gutters>
              <div class="rodape">
                <v-col md="12">
                  <div>
                    <div>
                      <v-btn v-if="!items.loading" @click="relacionados(items)" color="info">
                        Consultar Relacionados
                      </v-btn>
                      <b v-if="items.error">Essa norma não possui relacionados</b>
                    </div>


                    <div v-for="(relacionado, index) in items.relacionados" :key="index">
                      <div v-if="relacionado.relacaoDireta">
                        <b>{{ relacionado.relacaoDireta }}:</b> <a
                          v-bind:href="'https://conslegis.seg.es.gov.br/?norma=' + relacionado.normaRelacionada"
                          target="_blank">
                          {{ relacionado.normaRelacionada }}
                        </a>
                      </div>

                      <div v-if="relacionado.relacaoIndireta">
                        <b>{{ relacionado.relacaoIndireta }}:</b>
                        <a v-bind:href="'https://conslegis.seg.es.gov.br/?norma=' + relacionado.normaRelacionada"
                          target="_blank">
                          {{ relacionado.normaRelacionada }}
                        </a>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>


    <div v-else class="card-para-pc">

      <div class="cards" id="" v-for="(items, index) in paginateData" :key="index">

        <div class="card">
          <div class="cabecalho">
            <v-row>
              <v-col cols="12" md="6" class="infos">
                <div><b>N°:</b> {{ items.numeroAnoAtoNormativo }}</div>
                <div><b>Autoria:</b> {{ items.autoriaAtoNormativo }}</div>
                <div><b>Ato Normativo:</b> {{ items.tipoAtoNormativo }}</div>
                <div><b>Publicação:</b> {{ items.publicacao }}</div>
              </v-col>
              <v-col cols="12" md="3" class="infos">
                <div v-for="(link, index) in items.links" :key="index">
                  <a :href="link.uriDestino" target="_blank">{{ link.texto }} </a>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-divider class="mx-4" />
          <div>
            <v-row no-gutters>
              <div class="rodape">
                <v-col md="12">
                  <div><b>Descrição:</b> {{ items.descricao }}</div>
                </v-col>
              </div>
            </v-row>
            <v-row no-gutters>
              <div class="rodape">
                <v-col md="12">
                  <div>
                    <div>
                      <v-btn v-if="!items.loading" @click="relacionados(items)" color="info">
                        Consultar Relacionados
                      </v-btn>
                      <b v-if="items.error">Essa norma não possui relacionados</b>
                    </div>


                    <div v-for="(relacionado, index) in items.relacionados" :key="index">
                      <div v-if="relacionado.relacaoDireta">
                        <b>{{ relacionado.relacaoDireta }}:</b> <a
                          v-bind:href="'https://conslegis.seg.es.gov.br/?norma=' + relacionado.normaRelacionada"
                          target="_blank">
                          {{ relacionado.normaRelacionada }}
                        </a>
                      </div>

                      <div v-if="relacionado.relacaoIndireta">
                        <b>{{ relacionado.relacaoIndireta }}:</b>
                        <a v-bind:href="'https://conslegis.seg.es.gov.br/?norma=' + relacionado.normaRelacionada"
                          target="_blank">
                          {{ relacionado.normaRelacionada }}
                        </a>
                      </div>
                    </div>
                  </div>
                </v-col>
              </div>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <div class="paginated">

      <ul class="pagination" v-if="dadosLista.length > 5 || currentPage > 1">

        <li class="pagination-item" title="Voltar à Primeira Página">
          <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
            <v-icon>mdi-chevron-double-left</v-icon>
          </button>
        </li>

        <li class="pagination-item" title="Página Anterior">
          <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage">
            <v-icon>mdi-chevron-left</v-icon>
          </button>
        </li>

        <li class="pagination-item" v-for="(page, index) in pages" :key="index">
          <button type="button" @click="onClickPage(page.number)" :disabled="page.isDisabled"
            :class="{ active: isPageActive(page.number) }">
            {{ page.number }}
          </button>
        </li>

        <li class="pagination-item" title="Próxima Página">
          <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
            <v-icon>mdi-chevron-right</v-icon>
          </button>
        </li>

        <li class="pagination-item" title="Ir Para Última Página">
          <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
            <v-icon>mdi-chevron-double-right</v-icon>
          </button>
        </li>
      </ul>
    </div>

  </v-container>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Inputs from '@/components/Inputs.vue';
import axios from 'axios';

export default {
  name: "ConsultarAtos",
  components: {
    Inputs
  },
  props: {
    dadosLista: {
      type: Array,
    },
    maxVisibleButtons: {
      type: Number,
      default: 6,
    },
    totalPages: {
      type: Number,
    },
    total: {
      type: Number,
    },
    perPage: {
      type: Number,
    },
    currentPage: {
      type: Number,
    }
  },
  data: () => ({
    qtd: [5, 10, 15, 20, 25, 50, 75, 100],
    qtdModel: 5,
    menu1: false,
    menu2: false,
    loading: false,
  }),
  computed: {
    ...mapState({
      showProgressBar: "showProgressBar",
      listaDadosAPI: "listaDadosAPI",
      errorAxios: "errorAxios",
      errorMaisdeMilResultados: "errorMaisdeMilResultados",
      errorAtoNaoEncontrado: "errorAtoNaoEncontrado",
      erro500: "erro500",
      contador: "contadorLista",
    }),
    calculaTamanhoPagina() {
      var largura = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (largura > 600) return largura = false;
      console.log('largura boolean', largura);

      return largura;
    },
    pagination() {
      let contador;

      contador = Math.round(this.listaDadosAPI.length / 10);
      console.log(this.dadosLista);

      return contador;
    },
    paginateData() {
      let start = (this.currentPage - 1) * this.qtdModel;
      let end = start + this.qtdModel;

      return this.dadosLista.slice(start, end);
    },
    startPage() {
      if (this.currentPage === 1) return 1
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + (this.maxVisibleButtons - 1)
      }
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
    },
    pages() {
      let range = [];
      for (let i = this.startPage; i < this.endPage; i++) {
        range.push({
          number: i,
          isDisabled: i === this.currentPage
        })
      }
      return range;
    },
    isInFirstPage() { return this.currentPage === 1 },
    isInLastPage() { return this.currentPage === this.totalPages },
  },
  methods: {
    ...mapActions(["consultarRelacionados"]),
    ...mapGetters(["listarListaDadosAPI"]),
    ...mapMutations(["atualizarLista"]),
    relacionados(norma) {

      axios
        .post(`relacionado`, {
          numeroDaNorma: `${norma.numeroAnoAtoNormativo.split("/")[0]}`,
          anoDaNorma: norma.numeroAnoAtoNormativo.split("/")[1],
          numeroDaNormaRelacionada: 1,
          anoDaNormaRelacionada: 1,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(200)
            console.log(this.dadosLista)
            // this.loading = true;
            const resposta = response.data;
            let dados = this.listaDadosAPI;
            const index = dados.findIndex(
              (item) =>
                item.numeroAnoAtoNormativo === norma.numeroAnoAtoNormativo
            );

            if (index !== -1) {
              dados[index].loading = true;
              dados[index].relacionados = resposta.map((item) => ({
                normaRelacionada: item.numeroDaNormaRelacionada,
                relacaoDireta: item.relacaoDireta,
                relacaoIndireta: item.relacaoIndireta,
              }));
              // return dados;
            }
            console.log(dados)
            this.atualizarLista(dados)
          } else {
            console.error("Não existe para essa norma");
          }
        })
        .catch((error) => {
          console.log("Ocorreu um erro ao consultar as normas relacionadas");
          let dados = this.listaDadosAPI;
          const index = dados.findIndex(
            (item) =>
              item.numeroAnoAtoNormativo === norma.numeroAnoAtoNormativo
          );
          if (index !== -1) {
            dados[index].loading = true;
            dados[index].error = true;
            // return dados;
          }
          this.atualizarLista(dados)
          console.error(error);
        });
    },
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    },
    onPageChange(page) {
      this.currentPage == page;
    },
  },
  watch: {
    dadosLista(e) {
    },
    listaDadosAPI() {
    },
    teste(){
      console.log(dadosLista)
    }
  },
}
</script>

<style lang="scss">
.pagination {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  list-style-type: none;
  align-items: center;
  margin: 20px 10px 10px 10px;

  .pagination-item {
    button {
      margin: 0 !important;
      padding: .25rem .5rem;
      font-size: 1.3rem;
      border: none;
      border-radius: .25rem;
      background: none;

      &:hover {
        cursor: pointer;
        background-color: silver;
      }

      &[disabled="disabled"] {
        color: silver;
        cursor: default;

        &:hover {
          cursor: default;
          background-color: transparent;
        }

      }

      &.active {
        color: rgb(200, 102, 115);
      }
    }
  }
}

.spinner {
  // border: 1px solid red;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 50vh;
}

.errorAxios {
  width: 100%;
  height: 50vh;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(233, 233, 233);
  color: rgb(0, 0, 0);
}

.errorMaisDeMilResultados {
  width: 100%;
  height: 50vh;
  margin-top: 20px;
  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(233, 233, 233);
  color: rgb(0, 0, 0);
}

.contador-quantidade {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // border: 1px solid red;

  .contador {}

  .quantidade {
    width: 20%;
  }
}

.cards {
  margin: 20px 10px 10px 10px;

  display: flex;
  justify-content: center;
  align-content: center;

  border: 1px solid rgb(103, 102, 102);
  border-radius: 7px;

  .card {
    // border: 1px solid red;
    width: 100%
  }

  .cabecalho {
    margin-top: 8px;
    // border: 1px solid red;
    // width: 85vw;
    // max-width: 85vw;
    // margin-bottom: -15px;
  }

  .rodape {
    // border: 1px solid red;
    // margin-top: -15px;
  }

  .infos {
    margin-left: 20px;
  }

}

.card-para-celular {
  .cards-mobile {
    border: 1px solid rgb(103, 102, 102);
    margin-bottom: 20px;
  }
}

@media (max-width: 600px) {
  .errorAxios {
    padding: 20px;
    height: 30vh;
  }

  .errorMaisDeMilResultados {
    padding: 20px;
    height: 30vh;
  }

  .contador-quantidade {
    .quantidade {
      width: 35%;
    }
  }
}

a {
  color: blue;
  /* Cor do texto do link */
  text-decoration: underline;
  /* Sublinhado no link */
  cursor: pointer;
  /* Altera o cursor ao passar sobre o link */
}

a:hover {
  color: red;
  /* Mudança de cor ao passar o mouse */
}
</style>>
