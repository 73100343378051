<template>
  <div>
    <v-app-bar color="rgb(32, 139, 170)" app class="arredondar">
      <div class="app-bar-content">
        <v-btn ripple color="rgb(200, 102, 115)" @click="drawer = !drawer">
          <v-icon title="Menu" color="#FFF">mdi-menu</v-icon>
        </v-btn>
        <h2 class="text transition-default">Consultar Normas</h2>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :temporary="false"
      disable-resize-watcher
      :mini-variant="!menuCompact.hidden"
      mini-variant-width="100"
      app
    >
      <div id="sidebar">
        <div class="sidebar-body">
          <div class="imagem">
            <div class="brasao">
              <img
                src="../assets/Brasao_Governo_500.png"
                alt="..."
                width="150"
              />
            </div>
            <div class="title"><h1>SEG</h1></div>
            <div class="subtitle"><p>SECRETARIA DE ESTADO DO GOVERNO</p></div>
          </div>

          <div class="fundo" style="background-color: rgb(200, 102, 115)">
            <p
              class="font-weight-bold text-uppercase mb-0"
              style="padding: 5px; color: white"
            >
              Menu
            </p>
          </div>

          <v-row>
            <v-col class="px-2" style="margin: 10px 2px 10px 10px">
              <v-icon class="">mdi-text-box-search</v-icon>
              <router-link to="/" class="link" style="color: rgb(32, 139, 170)"
                >Consultar Normas</router-link
              >
            </v-col>
          </v-row>

          <div class="fundo" style="background-color: rgb(200, 102, 115)">
            <p
              class="font-weight-bold text-uppercase mb-0"
              style="padding: 5px; color: white"
            >
              OnBase
            </p>
          </div>

          <div>
            <v-row>
              <v-col class="px-2" style="margin: 10px 2px 10px 10px">
                <v-icon>mdi-book-plus</v-icon>
                <a
                  href="https://conteudo.seg.es.gov.br/appnet/UnityForm.aspx?d1=AbRdTSFXkuPEvNrlciuoUn2tIUTsUeItDq1o6xdcjnFlA%2bkLxTsokxYgd9Eol%2brgkRptV9d0w%2bD3SvD6lGjWNlwEKwA7Pokus3xUJrjAr9wFswBjnwOGQaZ6%2bK2PKeWY3LTbrgAWl%2bi2rUorYnt0NcaN8Kh4LUpk5D7K4jSmUOgwT1k6oHXU4u0ZOF9APGDC7mxGM38OmW1sK%2baQDalfQigU2n9LiiVtyCnUz76xmaUE"
                  class="text-dark link"
                  style="text-decoration: none; color: #000"
                  target="_blank"
                >
                  Adicionar Atos
                </a>
              </v-col>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-col class="px-2" style="margin: 10px 2px 10px 10px">
                <v-icon>mdi-text-box-search</v-icon>
                <a
                  href="https://conteudo.seg.es.gov.br/AppNet/docpop/docpop.aspx?clienttype=html&cqid=316"
                  class="text-dark link"
                  style="text-decoration: none; color: #000"
                  target="_blank"
                >
                  Acompanhar Atos
                </a>
              </v-col>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-col class="px-2" style="margin: 10px 2px 10px 10px">
                <v-icon>mdi-text-box-check</v-icon>
                <a
                  href="https://conteudo.seg.es.gov.br/portalvalidacao/"
                  class="text-dark link"
                  style="text-decoration: none; color: #000"
                  target="_blank"
                >
                  Validar Documentos
                </a>
                <!-- A rota aqui em baixo está funcionando, só está comentado pq não foi utilizado -->
                <!-- <router-link to="/validation" class="link">Validar Documentos</router-link> -->
              </v-col>
            </v-row>
          </div>
          <!-- <div>
                    <v-row>
                        <v-col class="px-2" style="margin: 10px 2px 10px 10px;">
                            <v-icon>mdi-text-box-check</v-icon>
                            <router-link to="/validation" class="link">Validar Documentos</router-link>
                        </v-col>
                    </v-row>
                </div> -->
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data: () => {
    return {
      drawer: true,
      menuCompact: {
        hidden: true,
      },
    };
  },
};
</script>

<style lang="scss">
.app-bar-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .transition-default {
    margin-top: 5px;
    transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    color: #fff;
    font-family: sans-serif;
    font-weight: 100;
  }
}

#sidebar {
  border: 1px solid rgb(161, 164, 164);
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
  user-select: none;

  .sidebar-body {
    flex-grow: 1;
    .imagem {
      .brasao {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 10px;
        margin-bottom: 10px;
      }
      .title {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .subtitle {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        // margin: 10px;
      }
    }

    .link {
      cursor: pointer;
      color: #000;
      text-decoration: none;
    }
  }
  .fundo {
    display: flex;
    align-items: center;
    background-color: rgb(199, 199, 199);
    p {
      margin-left: 10px;
    }
  }
}
@media (max-width: 600px) {
  .text {
    font-size: 25px;
  }
}
</style>
