import Vue from 'vue';
import VueRouter from 'vue-router';

import Menu from '../views/Menu.vue';
import Validation from '../views/Validation.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'menu',
    component: Menu
  },
  {
    path: '/validation',
    name: 'validation',
    component: Validation,
    
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
