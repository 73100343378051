<template>
    <div>
        <div class="container-fluid gov-header hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content hidden-print" id="gov-header-wrapper">
            <div class="container">
                <div class="row">
                    <div class="span-12 col-xs-12 col-sm-12 col-md-9 col-md-offset-3 col-lg-12 col-lg-offset-0">
                        <div class="zone zone-header-gov">
                            <div class="img-campanha-container invisible pull-left">
                                <span class="img-campanha"></span>
                            </div>
                            <div class="gov-menu-wrapper pull-right">
                                <ol class="gov-menu">
                                    <li>
                                        <ol type="a" class="gov-menu-accessibility">
                                            <li class="first-element constrast-element">
                                                <a title="Aplicar contraste" class="action-contraste" href="javascript:void(0);">
                                                    <i class="fa fa-adjust"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Retornar ao tamanho original do texto" class="jfontsize-d2" href="javascript:void(0);">
                                                    <span class="icon-text">A=</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Aumentar tamanho do texto" class="jfontsize-p2" href="javascript:void(0);">
                                                    <span class="icon-text">A+</span>
                                                </a>
                                            </li>
                                            <li class="last-element">
                                                <a title="Diminuir tamanho do texto" class="jfontsize-m2" href="javascript:void(0);">
                                                    <span class="icon-text">A-</span>
                                                </a>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid gov-bar-container color-tertiary-background-color hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content">
            <div class="row">
                <div class="span-2 col-md-1 col-lg-2 col-gov-bar col-gov-bar-left" style="width: 0px; display: none; padding: 0px;">
                    <div class="color-quaternary-background-color secondary-bar secondary-bar-top">
                    </div>
                    <div class="color-quinary-background-color secondary-bar secondary-bar-bottom">
                    </div>
                </div>
                <div class="span-8 col-md-10 col-lg-8 col-gov-bar col-gov-bar-center">
                    <div class="row">
                        <div class="span-2 col-xs-2 col-sm-2 col-md-2 col-lg-2 col-gov-bar-name">
                            <div class="color-quaternary-background-color secondary-bar secondary-bar-top" style="width: 140%;">
                                <div class="color-quaternary-background-color parallelogram parallelogram-1">
                                </div>
                            </div>
                            <div class="color-quinary-background-color  secondary-bar secondary-bar-bottom" style="width: 80%;">
                                <div class="color-quinary-border-top-color trapezoid trapezoid-1">
                                </div>
                            </div>
                        </div>
                        <div class="span-10 col-xs-10 col-sm-10 col-md-10 col-lg-10 col-gov-bar-logo">
                            <div class="diamond-effect">
                                <div class="logo-governo text-right">
                                    <div class="container-img-logo-governo">
                                        <a href="https://seg.es.gov.br/" target="_blank" title="Acessar portal Secretaria de Estado do Governo">
                                            <img src="//cdn.es.gov.br/images/logo/governo/SECOM/Brasao_Governo_Secretaria_horizontal_white_right_small.png" alt="brasão Governo do Estado Espírito Santo">
                                            <span class="name-secretariat">
                                                Secretaria de Estado do Governo
                                            </span>
                                        </a>
                                    </div>
                                </div>
                                <div class="triangle triangle-1">
                                </div>
                                <div class="triangle triangle-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="span-2 col-md-1 col-lg-2 col-gov-bar col-gov-bar-right" style="width: 0px; display: none; padding: 0px;">
                </div>
            </div>
        </div>
        <header class="container-fluid header-bar-container color-primary-background-color menu-color-color hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content" id="layout-header">
            <div class="row">
                <div class="span-12 col-md-12 col-lg-12" id="col-gov-bar-center-id">
                    <div class="row  row-navigation">
                        <div class="span-9 col-xs-8 col-sm-8 col-md-8 col-md-offset-4 col-lg-8 col-lg-offset-4 color-primary-background-color col-gov-bar-navigation col-gov-bar-navigation-header">
                            <div class="row col-gov-bar-content" style="overflow: hidden; position: relative;">
                                <div class="span-8 col-xs-12 col-sm-9 col-md-8 col-lg-8 container-navigation-menu" style="padding-right: 0;">
                                    <div class="zone zone-navigation">
                                        <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-navigation widget-midias-sociais-widget widget" style="padding-right: 0;">
                                            <div class=" ">
                                                <div class="row">
                                                    <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-right: 0;">
                                                        <div class="oc-social-icon clearfix">
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div class="triangle triangle-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="fixed-top-header hidden-print">
            <div class="container-fluid gov-header-accessibility hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content color-quaternary-background-color">
                <div class="container gov-header" style="">
                    <div class="row">
                        <div class="span4 col-xs-12 col-sm-6 col-md-6 col-lg-4">
                            <span class="gov-name">Governo do Estado do Espírito Santo</span>
                        </div>
                        <div class="span8 col-xs-12 col-sm-6 col-md-6 col-lg-8 text-right">
                            <div class="gov-menu-wrapper">
                                <ol class="gov-menu">
                                    <li>
                                        <ol type="a" class="gov-menu-accessibility">
                                            <li class="first-element constrast-element">
                                                <a title="Aplicar contraste" class="action-contraste" href="javascript:void(0);">
                                                    <i class="fa fa-adjust"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Retornar ao tamanho original do texto" class="jfontsize-d2" href="javascript:void(0);">
                                                    <span class="icon-text">A=</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a title="Aumentar tamanho do texto" class="jfontsize-p2" href="javascript:void(0);">
                                                    <span class="icon-text">A+</span>
                                                </a>
                                            </li>
                                            <li class="last-element">
                                                <a title="Diminuir tamanho do texto" class="jfontsize-m2" href="javascript:void(0);">
                                                    <span class="icon-text">A-</span>
                                                </a>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content">
                <div class="container">
                    <div class="row">
                        <div class="span-4 col-xs-4 col-sm-4 col-md-3 col-lg-4 col-gov-bar-content col-gov-bar-name">
                            <div class="zone zone-header">
                                <div class="row sec-name-row">
                                    <div class="span-12 col-xs-12 col-xs-offset-0 col-sm-9 col-sm-offset-3 col-md-12 col-md-offset-0 col-lg-12 col-lg-offset-0">
                                        <span class="system-name">@ViewData["Title"]</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="span-8 col-xs-8 col-sm-8 col-md-9 col-lg-8 col-gov-bar-content col-gov-bar-navigation">
                            <div class="row col-gov-bar-content">
                                <div class="span-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="zone zone-navigation zone zone-navigation">
                                        <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-navigation widget-menu-widget widget widget-navigation widget-menu-widget widget">
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid color-tertiary-background-color gov-bar-container gov-bar-container-mobile hidden-md hidden-lg hidden-desktop mobile-content">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-12 text-center">
                        <div class="logo-governo">
                            <div class="container-img-logo-governo">
                                <img src="//cdn.es.gov.br/images/logo/governo/SECOM/Brasao_Governo_Secretaria_horizontal_white_right_small.png" alt="brasão Governo do Estado Espírito Santo">
                                <span class="name-secretariat">
                                    Secretaria de Estado do Governo
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <header class="container-fluid header-bar-container hidden-md hidden-lg hidden-desktop mobile-content">
            <div class="container">
                <div class="row">
                    <div class="span-12 col-xs-12 col-sm-12 col-gov-bar-name">
                        <div class="zone zone-header zone zone-header">

                            <div class="text-center print">Governo do Estado do Espírito Santo</div>
                            <div class="row sec-name-row">
                                <div class="span-12 col-xs-12 col-xs-offset-0 col-sm-9 col-sm-offset-3 col-md-12 col-md-offset-0 col-lg-12 col-lg-offset-0">
                                    <a href="/" title="Ir para a Página Inicial">
                                        Ir para a Página Inicial
                                        <div class="site-name-family-font-family name-sec name-site"> </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-fluid navbar navbar-default color-primary-background-color navbar-mobile hidden-md hidden-lg hidden-desktop mobile-content">
            <div class="container">
                <div class="row">
                    <div class="span-12 col-xs-12 col-sm-12 column-navbar-mobile">
                        <div class="navbar-header navbar-header-mobile">
                            <ul>
                                <li>
                                    <ul class="accessibility-mobile">
                                        <li><a title="Aplicar contraste" class="menu-color-color action-contraste" href="javascript:void(0);">Contraste</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content hide-print">
            <div class="container">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 content-first-column" style="max-height: 30px;">
                        <div class="row sec-name-row">
                            <div class="span-12 col-xs-12 col-xs-offset-0 col-sm-9 col-sm-offset-3 col-md-12 col-md-offset-0 col-lg-12 col-lg-offset-0">
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9" style="padding: 0;"></div>
                </div>
            </div>
        </div>

        <!-- @RenderBody() -->

        <div class="container-fluid group footer-wrapper " id="layout-footer">
            <footer class="container" id="footer">
                <div class="row group" id="footer-sig">
                    <div class="span-12 col-lg-12  hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content ">
                        <div class="row">
                            <div class="zone zone-footer">
                                <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-footer widget-menu-widget widget">
                                    <div class=" ">
                                        <div class="row">
                                            <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content">
                                                    <div class="row footer-menu menu menu-menu-do-rodape">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div class="span-12 col-xs-12 col-sm-12 hidden-md hidden-lg hidden-desktop mobile-content">
                        <div class="row contact-info ">
                            <div class="zone zone-footer-quad-first">
                                <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-footer-quad-first widget-contato-widget widget">
                                    <div class=" ">
                                        <div class="row">
                                            <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <p>
                                                    <b class="name-institute">
                                                        Secretaria de Estado do Governo (SEG)
                                                    </b>
                                                    <br>
                                                    Rua Sete de Setembro, 362 - 6º e 7º andar - Palácio Fonte Grande
                                                    CEP: 29015-905 - Vitória / ES
                                                    Tel.: (27) 3636-1221 | 3636-1222
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div class="zone zone-footer-quad-second hidden-print">
                                <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-footer-quad-second widget-midias-sociais-widget widget">
                                    <div class=" ">
                                        <div class="row">
                                            <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="oc-social-icon clearfix">
                                                    <a title="facebook" class="facebook">
                                                        <span class="oc-icon-facebook"></span>
                                                    </a>
                                                    <a title="youtube" class="youtube">
                                                        <span class="oc-icon-youtube"></span>
                                                    </a>
                                                    <a title="twitter" class="twitter">
                                                        <span class="oc-icon-twitter"></span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div class="container-fluid footer-bar-container hidden-xs hidden-sm hidden-phone hidden-tablet desktop-content hidden-print">
            <div class="row bar-red-footer">
                <div class="span-2 col-md-1 col-lg-2 col-gov-bar col-gov-bar-left">
                    <div class="secondary-bar color-quaternary-background-color secondary-bar-top secondary-bar-top-footer"></div>
                </div>
                <div class="span-8 col-md-10 col-lg-8 col-gov-bar col-gov-bar-center">
                    <div class="row footer-contact-info">
                        <div class="span-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-gov-bar-name">
                            <div class="secondary-bar color-quaternary-background-color secondary-bar-top secondary-bar-top-footer" style="width: 120%;">
                                <div class="parallelogram color-quaternary-background-color parallelogram-1 parallelogram-1-footer"></div>
                            </div>
                            <div class="contact-info">
                                <div class="zone zone-footer-quad-first zone zone-footer-quad-first">
                                    <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-footer-quad-first widget-contato-widget widget widget-footer-quad-first widget-contato-widget widget">
                                        <div class=" ">
                                            <div class="row">
                                                <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p>
                                                        <b class="name-institute">
                                                            Secretaria de Estado do Governo (SEG)
                                                        </b>
                                                        <br>
                                                        Rua Sete de Setembro, 362 - 6º e 7º andar - Palácio Fonte Grande
                                                        CEP: 29015-905 - Vitória / ES
                                                        Tel.: (27) 3636-1221 | 3636-1222
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <div class="span-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 color-tertiary-background-color col-gov-bar-logo col-gov-bar-logo-footer">
                            <div class="diamond-effect">
                                <div class="triangle triangle-1"></div>
                                <div class="triangle triangle-2"></div>
                                <div class="triangle triangle-3"></div>
                            </div>
                        </div>
                        <div class="span-3 col-xs-3 col-sm-3 col-md-3 col-lg-3 col-gov-bar-center-right hidden-print">
                            <div class="color-quinary-background-color parallelogram parallelogram-2"></div>
                            <div class="zone zone-footer-quad-second zone zone-footer-quad-second">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="span-2 col-md-1 col-lg-2 col-gov-bar col-gov-bar-right">
                    <div class="secondary-bar color-quinary-background-color secondary-bar-top secondary-bar-top-footer"></div>
                </div>
            </div>
        </div>
        <div class="container-fluid credits">
            <div class="container">
                <div class="row">
                    <div class="span-12 col-lg-12">
                    </div>
                </div>
            </div>
        </div>
        <div style="display:none;">
            <div class="zone zone-script">
                <article class="col-xs-12 col-sm-12 col-md-12 col-lg-12 widget-script widget-html-widget widget">
                    <div class=" content-wrapper ">
                        <div class="row">
                            <div class="span12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
  name: 'Validar Dados',

}
</script>

<style lang="scss" scoped>

  // .container{
  //   margin-top: 20px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;

  //   border: 1px solid red;

  //   height: 90vh;
  // }

</style>

